import * as Style from "../style/StyledComponents";
import { abbreviateAddress, chain, getEtherscanAddressLink, getEtherscanTxnLink, getEtherscanContractLink, links } from "./Config";
import {useState, useEffect} from 'react'

import { InjectedConnector } from 'wagmi/connectors/injected'
import { useConnect , useAccount, useDisconnect } from 'wagmi'
import { usePrepareContractWrite, useContractWrite , useContractReads  } from 'wagmi'
import { waitForTransaction, fetchBalance  } from '@wagmi/core'

import { injected } from 'wagmi/connectors'
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

BigNumber.config({ EXPONENTIAL_AT: 50 })

const contractABI_MintFrontEnd = require("../abi/MintFrontend.json");

const frontendContract = {
  address: process.env.REACT_APP_CONTRACT_FRONTEND,
  abi: contractABI_MintFrontEnd,
}


const MINT_STATUS = {
  DISABLED:0,
  PUBLIC:1
}

export const Mint = () => {

  const { isConnected , address } = useAccount()
  const { disconnect } = useDisconnect()
  const { connectors, connect } = useConnect({
    connector: new InjectedConnector({chains:[chain]}),
    chainId: chain.id,
  });

  const [currentSupply, setCurrentSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(1000);
  const [mintPrice, setMintPrice] = useState("0.0075");
  const [mintCount, setMintCount] = useState(1);
  const [mintStatus, setMintStatus] = useState(0);
  const [maxMintAmount, setMaxMintAmount] = useState(1);

  const [walletBalance, setWalletBalance] = useState("0.0");

  // calculated
  const [totalMintPrice, setTotalMintPrice] = useState("0.0");

  const { data:readData, isError:readError, isLoading:readIsLoading } = useContractReads({
    enabled:true,
    contracts: [
      {
        ...frontendContract,
        functionName: 'totalSupply',
      },
      {
        ...frontendContract,
        functionName: 'MAX_TOKEN_SUPPLY',
      },
      {
        ...frontendContract,
        functionName: 'maxPerTxn',
      },
      {
        ...frontendContract,
        functionName: 'mintStatus',
      },
      {
        ...frontendContract,
        functionName: 'price',
      }
    ],
  });

  async function checkBalance() {
     const balance = await fetchBalance({
        address: address,
      });

      const price = new BigNumber(parseInt(balance.value));
      const total = price.multipliedBy("1E-18");
      setWalletBalance(total.toString());

      console.log(balance);
  }

  useEffect(()=>{

    if (isConnected && address) {
      checkBalance();
    }
  }, [address,isConnected]);

  useEffect(()=>{
    if (readData != null) {
      console.log("Contract read:", readData);

      setCurrentSupply( parseInt(readData[0].result));
      setMaxSupply( parseInt(readData[1].result));
      setMaxMintAmount( parseInt(readData[2].result));
      setMintStatus(readData[3].result);

      const price = new BigNumber(parseInt(readData[4].result));
      const total = price.multipliedBy("1E-18");
      setMintPrice(total.toString());

      //setMintPrice( parseInt(readData[4].result)); // todo

    }

  }, readData);


  const { data, isLoading, isSuccess, error, isError, write, reset } = useContractWrite({
    address: process.env.REACT_APP_CONTRACT_FRONTEND,
    abi: contractABI_MintFrontEnd,
    functionName: 'mintRocks',
    chainId: chain.id,
    account: address,

    onSettled(data, error) {
      console.log('Transaction Settled', { data, error })
    },
  });

  useEffect(()=>{
    const price = new BigNumber(mintPrice);
    const total = price.multipliedBy(mintCount);
    setTotalMintPrice(total.toString());
  },  [mintPrice, mintCount]);


  function onClickPlus() {
    setMintCount(Math.min(maxMintAmount, mintCount+1));
  }

  function onClickMinus() {
    setMintCount(Math.max(1, mintCount-1));
  }

  function onClickConnect() {
    console.log("CONNECT");
    connect();
  }

  function onClickDisconnect() {
    console.log("Disconnect");
    disconnect();
    reset();
  }

  async function onClickMint() {
    reset();

    console.log(`Minting: mint(${mintCount}) with payable ${totalMintPrice} ETH`);
    write({
          args: [mintCount],
          from: address,
          value: ethers.parseEther(totalMintPrice),
    });
  }

  return (
    <div>

    {<>
        <Style.Button disabled>minted out!</Style.Button>
        <p style={{fontSize:"0.75em", marginTop:"5px"}}>✔️ 1000/1000 ( View on <a href={links.opensea} target="_blank">OpenSea</a> / <a href={links.blur} target="_blank">Blur</a> )</p>
      </>}

{/*
    {!isConnected && <><Style.Button onClick={onClickConnect}>connect</Style.Button><p style={{fontSize:"0.75em", marginTop:"5px", display:"none"}}>(Network: <a href={links.blast} target="_blank">{chain.name}</a>)</p></>}


    {isConnected && mintStatus==MINT_STATUS.DISABLED && <Style.Button disabled={true}>Minting not available</Style.Button>}
    {isConnected && mintStatus==MINT_STATUS.PUBLIC && currentSupply == maxSupply && <Style.Button disabled={true}>Minted Out</Style.Button>}

    {isConnected && mintStatus==MINT_STATUS.PUBLIC && currentSupply < maxSupply && 
      <div style={{textAlign:"left"}}>
      <div style={{display:"flex", flexDirection:"row"}}>
        <Style.Button disabled={isLoading} hidden={mintCount<=1} onClick={onClickMinus}>-</Style.Button>
        <Style.Button disabled={isLoading} onClick={onClickMint}>Mint {mintCount} Rock{mintCount>1?"s":""}</Style.Button>
        <Style.Button disabled={isLoading} hidden={mintCount>=maxMintAmount || (currentSupply+mintCount+1 > maxSupply) } onClick={onClickPlus}>+</Style.Button>


        </div>

         <div style={{marginLeft:"0em"}}>
    <span style={{fontSize:"0.9em", fontFamily:"Header", fontWeight:"bold",color: "var(--black)"}}>✔️ Total: <span style={{fontSize:"1.3em", color: "var(--black)", fontWeight:"bold"}}>{totalMintPrice} Ξ</span></span>
    <span style={{marginLeft:"0.5em", fontSize:"0.75em", color: "var(--gray-600)"}}>({mintCount} rock{mintCount>1?"s":""} x {mintPrice} Ξ each)</span>
    </div>
    </div>
      }

    */}

   


    {isConnected && <div style={{marginTop:"1.5em", fontSize:"0.75em",textAlign:"left",}}>🟢 <strong>Connected</strong>: <a href={getEtherscanAddressLink(address)} target="_blank">{abbreviateAddress(address)}</a> (<a onClick={onClickDisconnect}>Disconnect</a>)</div>}
    {isConnected && <div style={{fontSize:"0.75em",textAlign:"left",}}>(Your Balance: {walletBalance} Ξ)</div>}
    {isConnected && 
      <div style={{marginTop:"1.2em", fontSize:"0.75em", textAlign:"left"}}>
        ▪ Mint Status: <strong>{mintStatus==MINT_STATUS.PUBLIC?"🟢 Active":"Closed"}</strong>
        <br/>
        ▪ Total Minted: {currentSupply==maxSupply?"✔️ ":""}<strong>{currentSupply} / {maxSupply}</strong>
        <br/>
         ▪ Mint Price (each): {mintPrice} Ξ
        <br/>
        ▪ Max Mints Per Txn: {maxMintAmount}
      </div>
    }


    {isError && <Style.ErrorMessage>Error: {error.message}</Style.ErrorMessage>}

    {isSuccess && data != null && <Style.SuccessMessage><strong>Transaction Sent</strong>:<br/><a href={getEtherscanTxnLink(data.hash)} target="_blank">{data.hash}</a></Style.SuccessMessage>}

    </div>
  )
}
