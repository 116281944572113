import * as Style from "../style/StyledComponents";
import Navbar from "./Navbar";
import {Mint} from "./Mint";
import { abbreviateAddress, chain, links } from "./Config";

import imgHero from "../images/herorocks-2.png";

function SectionHero() {
	return (
         <Style.Section>
      		<Style.HeroContainer >	

      			<Style.ColumnHeroImage>
      				<div style={{margin:"0 auto"}}>
      			    <Style.ResponsiveImage src={imgHero} />
      			    </div>
      			</Style.ColumnHeroImage>

      			<Style.ColumnHeroDesc>
      			   <h1>Blast Rocks</h1>
               <p><a href={links.twitter} target="_blank">Twitter</a> / <a href={links.discord} target="_blank">Discord</a> / <a href={links.contractFrontEnd} target="_blank">Contract</a></p>
      			   <br/>
      				<p>Fully onchain semi-generative premium rock collection on <a href={links.blast} target="_blank">Blast</a>. No roadmap, just rocks.</p>

              <br/>
              <Mint/>
      				
              <br/>

      				<Style.HiddenMobile><div style={{borderBottom:"1px solid white", fontFamily:"Header", padding:"0.25em 0", marginBottom:"0.0em", fontWeight:"bold",color:"var(--black)"}}>Collection Info</div></Style.HiddenMobile>
              <hr/>
      				<p style={{fontSize:"0.75em", textAlign:"left"}}>
          ▪ Created by <a href={links.twitter} target="_blank">@dailofrog</a><br/>
          ▪ Pixel Art: <a href={links.twitterGoop} target="_blank">@goopgoop_art</a><br/>
					▪ Release Date: Feb 29, 2024<br/>
          ▪ Max Supply: 1,000<br/>
					▪ Mint Price (each): 0.0075 Ξ<br/>
          ▪ Token Type: ERC-721 (100% onchain)<br/>
      		▪ Network: <a href={links.blast} target="_blank">{chain.name}</a><br/>
					▪ Contract: <a href={links.contractFrontEnd} target="_blank">{abbreviateAddress(process.env.REACT_APP_CONTRACT_FRONTEND,5)}</a><br/>
          ▪ Marketplace: <a href={links.opensea} target="_blank">OpenSea</a>, <a href={links.elementStore} target="_blank">Element</a>, <a href={links.blur} target="_blank">Blur</a>
					</p>
      			</Style.ColumnHeroDesc>
      		</Style.HeroContainer>
       
         </Style.Section>)
}

function FrontPage() {
  return (
      <>
        <SectionHero/>
      </>
  );
}

export default FrontPage;
