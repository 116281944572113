import { mainnet, goerli, sepolia } from 'wagmi/chains'
import { Chain } from 'wagmi'

// https://docs.blast.io/building/network-information
// https://0.12.x.wagmi.sh/react/chains
export const blastChain = {
  id: 81457,
  name: 'Blast',
  network: 'blast',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    //public: { http: ['https://rpc.ankr.com/blast/c2392e45a33e82f91b5fa4eea65e465eede3dca425bf8d459f3323b970e15642'] },
    //public: { http: ['https://rpc.ankr.com/blast/c2392e45a33e82f91b5fa4eea65e465eede3dca425bf8d459f3323b970e15642'] },
    default: { http: ['https://blast.blockpi.network/v1/rpc/public'] },
    public: { http: ['https://blast.blockpi.network/v1/rpc/public'] },
  },
  blockExplorers: {
    etherscan: { name: 'Blast Explorer', url: 'https://blastscan.io' },
    default: { name: 'Blast Explorer', url: 'https://blastscan.io' },
  }
} 

export function abbreviateAddress(address: string, charsLength: number = 4): string {
  if (typeof address !== 'string') return '';
  if (address.length < charsLength * 2 + 2) return address;

  const prefix = address.substr(0, charsLength + 2); // '0x' + charsLength characters
  const suffix = address.substr(-charsLength);

  return `${prefix}...${suffix}`;
}

export const blastTestnetChain = {
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blastsepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.ankr.com/blast_testnet_sepolia/c2392e45a33e82f91b5fa4eea65e465eede3dca425bf8d459f3323b970e15642'] },
    default: { http: ['https://rpc.ankr.com/blast_testnet_sepolia/c2392e45a33e82f91b5fa4eea65e465eede3dca425bf8d459f3323b970e15642'] },
    //public: { http: ['https://sepolia.blast.io'] },
    //default: { http: ['https://sepolia.blast.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'Blast Explorer', url: 'https://testnet.blastscan.io' },
    default: { name: 'Blast Explorer', url: 'https://testnet.blastscan.io' },
  }
} 


const getEtherScan = () => {
  switch (chain.network) {
    case "blast":  return "https://blastscan.io";
    case "blastsepolia":  return "https://testnet.blastscan.io";
    case "sepolia":  return "https://sepolia.etherscan.io";
    case "goerli":  return "https://goerli.etherscan.io";
  }
}
export const chain = blastChain; // <-- MAINNET SET LIVE
//export const chain = blastTestnetChain; // testnet
//export const chain = blastTestnetChain; // testnet

export const getEtherscanAddressLink = (hash) => {
  return `${getEtherScan()}/address/${hash}`;
}

export const getEtherscanTxnLink = (hash) => {
  return `${getEtherScan()}/tx/${hash}`;
}

export const getEtherscanContractLink = (hash) => {
  return `${getEtherScan()}/address/${hash}`;
}

console.log(`Chain: ${chain.name} (${chain.id})`)
console.log(`FrontEnd: ${getEtherscanContractLink(process.env.REACT_APP_CONTRACT_FRONTEND)}`)


export const links = {
  twitter: "https://twitter.com/dailofrog",
  twitterGoop: "https://twitter.com/goopgoop_art",
  discord: "https://discord.gg/KBAbk2a4PM",
  contractFrontEnd: getEtherscanContractLink(process.env.REACT_APP_CONTRACT_FRONTEND),
  blast: "https://blast.io/",
  blurStore: "https://blur.io/",
  opensea: "https://opensea.io/collection/blastrocks/",
  elementStore: "https://element.market/collections/blast_rocks",
  blur: "https://blur.io/blast/collection/blastrocks",
};
